/* -------------------- START: Grid & Card -------------------- */
.CoreGridViewContainer {
  /* Creating negative space around the container to inflate the boundry */
  /* This is needed to ensure that when in card view, the box shadow is properly visible */
  margin: -20px !important;
}

.CoreGridHeaderContainer {
  /* Compensating for the negative space around the parent container to deflate the boundry */
  /* This is needed to ensure that when in card view, the box shadow is properly visible */
  margin: 20px 20px 0 20px !important;
}

.CoreGridHeaderContainer .ButtonGroup {
  padding: 0px !important;
  margin-left: 10px !important;
}

.CoreGridHeaderContainer .ButtonGroup .Button {
  width: 50px !important;
  padding: 5px 15px !important;
  outline: none !important;
}

.CoreGridHeaderMenu .MenuItemAvatar {
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important;
  line-height: 1;
}

.ButtonGroup .MuiAvatar-img {
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important;
}

.CoreGridViewInnerContainer {
  /* Compensating for the negative space around the parent container to deflate the boundry */
  /* This is needed to ensure that when in card view, the box shadow is properly visible */
  padding: 20px 20px 8px 20px !important;
}

.CoreGridViewInnerContainerInfinite {
  /* Compensating for the negative space around the parent container to deflate the boundry */
  /* This is needed to ensure that when in card view, the box shadow is properly visible */
  padding: 20px !important;
}

.CoreGridFooterContainer {
  /* Compensating for the negative space around the parent container to deflate the boundry */
  /* This is needed to ensure that when in card view, the box shadow is properly visible */
  width: 100% !important;
  padding: 0 8px 8px 8px !important;
}

/* -------------------- END: Grid & Card -------------------- */

/* -------------------- START: CardWrapper -------------------- */
.card-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.card-wrapper .card-content {
  padding: 0 16px !important;
}

.card-wrapper .actions {
  display: flex !important;
  align-items: center !important;
}

.card-wrapper .MuiCardHeader-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  white-space: pre-line;
  overflow-wrap: anywhere;
}

.card-wrapper .MuiCardHeader-subheader {
  font-size: 16px !important;
  font-weight: 400 !important;
}

/* -------------------- END: CardWrapper -------------------- */

.avatar {
  border-radius: 8px !important;
  height: 48px !important;
  width: 48px !important;
}

/* -------------------- START: Info Page -------------------- */
.InfoCardWrapper {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
}

/* -------------------- END: Info Page -------------------- */

/* CSS For Dialog */
.MuiDialogContent-root {
  padding: 0 0 16px 0 !important;
}

.MuiDialogTitle-root {
  padding: 0 !important;
}

.MuiDialogContentText-root {
  padding: 16px 0 !important;
}

.MuiDialogActions-root {
  padding: 0 !important;
}

.dialog {
  cursor: move !important;
  z-index: 10000;
}

.dialogWrapper {
  cursor: 'move';
  padding: 16px;
  width: 100%;
  max-width: 480px;
  border-radius: 16px !important;
  z-index: 10000 !important;
}
.milesWrapper{
  max-width: 640px !important;
}

.dialogText {
  text-align: center;
}

.icon {
  max-width: 70px;
  margin: 0 auto 15px;
  display: block;
  width: 64px !important;
  height: 64px !important;
}

.dialogButton {
  margin-right: 5px;
}

.dialogFooter {
  padding: 0 !important;
}

/*----------- End --------------*/

/*----------- Flex Classes --------------*/
.flexRow {
  display: flex;
  flex-direction: row;
}

/*----------- End --------------*/

/* common css classes */
.m-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto;
}

.mr-50p {
  margin-right: 50% !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.mr-2 {
  margin-right: 16px !important;
}

.mr-3 {
  margin-right: 24px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 8px !important;
}

.ml-2 {
  margin-left: 16px !important;
}

.ml-3 {
  margin-left: 24px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mx-2 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mx-3 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 8px !important;
}

.m-2 {
  margin: 16px !important;
}

.m-3 {
  margin: 24px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 8px !important;
}

.p-2 {
  padding: 16px !important;
}

.p-3 {
  padding: 24px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 8px !important;
}

.pl-2 {
  padding-left: 16px !important;
}

.pl-2 {
  padding-left: 24px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 8px !important;
}

.pr-2 {
  padding-right: 16px !important;
}

.pr-2 {
  padding-right: 24px !important;
}

.iconSize18 {
  height: 18px !important;
  width: 18px !important;
}

.iconSize20 {
  height: 20px !important;
  width: 20px !important;
}

.iconSize24 {
  height: 24px !important;
  width: 24px !important;
}

.iconSize32 {
  height: 32px !important;
  width: 32px !important;
}

.iconSize48 {
  height: 48px !important;
  width: 48px !important;
}

.w-100 {
  width: 100px !important;
}

.w-125 {
  width: 125px !important;
}

.w-150 {
  width: 150px !important;
}

.w-175 {
  width: 175px !important;
}

.w-200 {
  width: 200px !important;
}

.w-225 {
  width: 225px !important;
}

.w-250 {
  width: 250px !important;
}

.mw-100 {
  min-width: 100px !important;
}

.mw-125 {
  min-width: 125px !important;
}

.mw-150 {
  min-width: 150px !important;
}

.mw-175 {
  min-width: 175px !important;
}

.mw-200 {
  min-width: 200px !important;
}

.mw-225 {
  min-width: 225px !important;
}

.mw-250 {
  min-width: 250px !important;
}

.mxw-100 {
  max-width: 100px !important;
}

.mxw-125 {
  max-width: 125px !important;
}

.mxw-150 {
  max-width: 150px !important;
}

.mxw-175 {
  max-width: 175px !important;
}

.mxw-200 {
  max-width: 200px !important;
}

.mxw-225 {
  max-width: 225px !important;
}

.mxw-250 {
  max-width: 250px !important;
}

.fixed-header-button {
  min-width: 56px !important;
  min-height: 40px !important;
  max-width: 56px !important;
  max-height: 40px !important;
  width: 56px !important;
  height: 40px !important;
  padding: 0 !important;
  outline: 0 !important;
}

.circularGridBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataFound {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.hide-element {
  display: none !important;
}

.text-no-wrap {
  white-space: nowrap !important;
}

.text-wrap {
  white-space: pre-wrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.break-word {
  word-wrap: break-word;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.flex {
  display: flex !important;
}

.items-center {
  align-items: center;
}

.MuiTableCell-root {
  padding: 15px !important;
}

.MuiOutlinedInput-input {
  -webkit-text-fill-color: initial !important;
}

.textDecorationNone {
  text-decoration: none;
}

.block-fullwidth {
  display: block;
  width: 100%;
}

.colorWhite {
  color: white !important;
}

.colorGrey {
  color: grey !important;
}

.opacity25 {
  opacity: 0.25 !important;
}

/* table css */
th{background:#DDDDDD !important;}
tr:nth-child(odd){
  background-color: #f9f9f9 !important;
  
}

.header-css{
  font-size: 14px !important;
  color: #343434;
}